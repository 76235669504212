import React from 'react';
import useBodyOverflowHidden from './useBodyOverflowHidden';
import { Close, infoBlack as info } from '../../../resources/Images';
import { directDeepLink } from '../../../utils/deepLinks';

const BottomDrawer = ({ drawerContent, closeDrawer }) => {
  useBodyOverflowHidden();

  const bottomDrawerRef = React.useRef();

  const outsideClickEvent = (e) => {
    if (e.target.className === 'bottomDrawerContainer') {
      handleDrawerClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', outsideClickEvent);
    bottomDrawerRef.current.style.bottom = `-${bottomDrawerRef.current.offsetHeight}px`;
    bottomDrawerRef.current.style.bottom = '0';
    return () => {
      document.removeEventListener('click', outsideClickEvent);
    };
  }, [outsideClickEvent]);

  const handleDrawerClose = () => {
    bottomDrawerRef.current.style.bottom = `-${bottomDrawerRef.current.offsetHeight}px`;
    setTimeout(() => {
      closeDrawer();
    }, 500);
  };

  return (
    <div className="bottomDrawerContainer">
      <div className="bottomDrawer" ref={bottomDrawerRef}>
        <div className="bottomDrawer__header">
          <span>Your Reward Details</span>
          <img src={Close} alt="crossIcon" onClick={handleDrawerClose} />
        </div>
        <div className="bottomDrawer__content">
          {drawerContent?.howToRedeem && (
            <div className="bottomDrawer__content__block">
              <div className="bottomDrawer__content__block__header">
                <img src={info} alt="icon" />
                <p>How to Redeem</p>
              </div>
              <ul>
                {drawerContent?.howToRedeem?.linkText && (
                  <li
                  onClick={directDeepLink({
                    screen: "UTIL_WEBVIEW",
                    paramOne:drawerContent?.websiteLink,
                    paramTracking:drawerContent?.code,
                  },"SOURCE_HOME")} 
                    dangerouslySetInnerHTML={{
                      __html: decodeURI(drawerContent?.howToRedeem?.linkText),
                    }}
                  ></li>
                )}
                {drawerContent?.howToRedeem?.bulletPoints?.map(
                  (point, index) => (
                    <li key={index}>{point}</li>
                  )
                )}
              </ul>
            </div>
          )}
          {drawerContent?.termsAndConditions && (
            <div className="bottomDrawer__content__block">
              <div className="bottomDrawer__content__block__header">
                <img src={info} alt="icon" />
                <p>Terms & conditions</p>
              </div>
              <ul>
                {drawerContent?.termsAndConditions?.linkText && (
                  <li
                    dangerouslySetInnerHTML={{
                      __html: drawerContent?.termsAndConditions?.linkText,
                    }}
                  ></li>
                )}
                {drawerContent?.termsAndConditions?.bulletPoints?.map(
                  (point, index) => (
                    <li key={index}>{point}</li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomDrawer;
