import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";

import './App.scss'

import ScrollToTop from './ScrollToTop'
import Home from './components/Home/Home'
import Leaderboards from './components/Leaderboards/Leaderboards'
import Rewards from './components/Rewards/Rewards'

export class App extends Component {
  constructor(){
    super();
    this.state={}
  }

  componentDidMount() {
    var paramToken =
      this.getQuery()["token"] || localStorage.getItem("pnwToken");
      localStorage.setItem("pnwToken", paramToken);
  }

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/leaderboards" component={Leaderboards} />
              <Route exact path="/rewards" component={Rewards} />
            </Switch>
          </ScrollToTop>          
        </BrowserRouter>
      </div>
    )
  }
}

export default App

