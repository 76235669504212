import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../config';
import Header from '../UI/Header';
import RewardList from './RewardList';

import '../Loader/loader.css';
import Coupons from './Coupons';
import Carousel from './Carousel';

function Rewards() {
  const [apiData, updateApiData] = useState();
  const [loading, updateLoading] = useState(true);
  const [apiError, updateApiError] = useState(false);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = () => {
    let queryToken = getQuery()['token'] || localStorage.getItem('pnwToken');
    // localStorage.setItem("pnwToken", queryToken)
    fetch(`${BASE_URL}v2/course/campaign/quiz/awards`, {
      method: 'GET',
      headers: {
        'x-access-token': queryToken,
      },
    })
      .then((res) => res.json())
      .then(
        (res) => {
          updateApiData(res && res.data);
          updateLoading(false);
        },
        (err) => {
          console.log(err);
          updateApiError(true);
          updateLoading(false);
        }
      );
  };

  const getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split('&')
      .map((qStr) => qStr.split('='))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  return (
    <div className="RewardsPage">
      {/* <Header />
      <div className="RewardsPage__Banner">
        <div className="RewardsPage__Banner--Head">Your Rewards</div>
        <div className="RewardsPage__Banner--Text">
          See & redeem awards you have <br /> already won in the contest
        </div>
      </div>
      {apiData && apiData.data && apiData.data.length === 0 ? (
        <div className="Error">
          <div className="Error--Text">Nothing here for you yet!</div>
          <div className="Error--Subtext">
            Keep predicting to win your rewards.
          </div>

          <div className="ErrorPopUp">An Error Occured</div>
        </div>
      ) : (
        <RewardList apiData={apiData} loading={loading} apiError={apiError} />
      )} */}
      {apiData?.awards?.length ? (
        <Coupons awards={apiData?.awards} />
      ) : (
        <Carousel
          subHeading={apiData?.text}
          coupons={apiData?.coupons}
          deepLink={apiData?.deeplink}
        />
      )}
    </div>
  );
}

export default Rewards;
