import React, { useEffect, useState } from 'react';
import { Gift } from '../../resources/Images';

import AwardList from '../Modals/AwardList';

function Rewards({
  hasPredicted,
  studentPurchaseTimeLeft,
  didWin,
  showRewards,
  toggleRewardModal,
  predictionMade,
  data,
}) {
  const [timeLeft, setTimeLeft] = useState();
  const [lastFewHours, checkLastFewHours] = useState(false);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState();

  let getTimeLeft = (seconds) => {
    if (seconds > 0) {
      if (seconds < 10800) {
        checkLastFewHours(true);
      }
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);

      var hours = h > 0 ? h : 0;

      var time = hours ? `${hours}h ${m}m ${s}s` : `${m}m ${s}s`;

      setTimeLeft(time);
      setTimeLeftInSeconds(seconds);
    }
  };

  useEffect(() => {
    if (studentPurchaseTimeLeft) {
      setInterval(() => {
        getTimeLeft(studentPurchaseTimeLeft);
        studentPurchaseTimeLeft -= 1;
      }, 1000);
    }
  }, [studentPurchaseTimeLeft]);

  return (
    <div
      className="Rewards"
      style={{
        backgroundColor: hasPredicted || didWin ? '#52B060' : '#FFF6EE',
      }}
    >
      <div className="Rewards__content">
        {hasPredicted ? (
          <div className="Rewards__content--predicted">
            <div className="Rewards__content--predicted--text">
              Now buy a course before time runs out to get rewards!
            </div>
            {timeLeft && timeLeftInSeconds >= 0 ? (
              <div className="Rewards__content--predicted--time">
                {timeLeft} left
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className="Rewards__content--text">{data?.title}</div>
        )}

        <div
          className="Rewards__content--button"
          onClick={() => toggleRewardModal()}
          style={{
            backgroundColor: hasPredicted || didWin ? '#FFF6EE' : '#C3443D',
            color: hasPredicted || didWin ? 'rgba(0, 0, 0, 0.87)' : '#FFFFFF',
          }}
        >
          {data?.button?.text}
        </div>
      </div>

      <img loading="lazy" className="Rewards__Image" src={Gift} alt="gift" />
      {showRewards && (
        <AwardList
          lastFewHours={lastFewHours}
          timeLeft={timeLeft}
          timeLeftInSeconds={timeLeftInSeconds}
          toggleModal={toggleRewardModal}
          hasPredicted={hasPredicted}
          predictionMade={predictionMade}
        />
      )}
    </div>
  );
}

export default Rewards;
