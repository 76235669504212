import React, { useEffect, useRef, useState } from 'react';
import Loader from '../Loader/Loader';

import { info } from '../../resources/Images';

import { PAYTM, MYNTRA, AMAZON, REDBUS, BOAT, ZOOMIN, GODESI, YOGABAR, THC, WICKEDGUD } from './TnC.js';
import HowToRedeem from '../Modals/HowToRedeem';
import Error from '../Loader/Error';

import { ToastContainer, toast } from 'react-toastify';

function RewardList({ apiData, loading, apiError }) {
  const textArea = useRef(null);
  const [textToCopy, setTextToCopy] = useState();
  const [showHowModal, setShowHowModal] = useState(false);
  const [howToRedeem, setHowToRedeem] = useState({});
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const [RewardsToShow, updateRewardsToShow] = useState([]);
  const [PastWonList, setPastWonList] = useState();

  useEffect(() => {
    if (apiData) {
      setPastWonList(apiData);
    }
  }, [apiData]);

  useEffect(() => {
    if (textToCopy) {
      copyToClipboard();
    }
  }, [textToCopy]);

  useEffect(() => {
    if (PastWonList) {
      BuildRewardArray();
    }
  }, [PastWonList]);

  useEffect(() => {
    if (RewardsToShow) {
      console.log(RewardsToShow, '!X!X!X!X!X!X!X!X');
    }
  }, [RewardsToShow]);

  const toggleHowModal = () => {
    setShowHowModal(!showHowModal);
  };

  const modalData = (htr, tnc, wl, wl2) => {
    setHowToRedeem(htr);
    setTermsAndConditions(tnc);
    setShowHowModal(true);
  };

  const copyToClipboard = (e) => {
    textArea && textArea.current && textArea.current.select();
    document.execCommand('copy');
    toast.success('Copied Successfully');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    // this.copiedSuccessfully()
  };
  const AllRewards = [PAYTM, MYNTRA, AMAZON, REDBUS, BOAT, ZOOMIN, GODESI, YOGABAR, THC, WICKEDGUD];
  const BuildRewardArray = () => {
    var RewardsArray = [];
    PastWonList &&
      PastWonList.map((data) => {
        AllRewards.map((reward) => {
          if (reward.id == data.id) {
            RewardsArray = [...RewardsArray, { ...reward, code: data.code }];
          }
        });
      });
    updateRewardsToShow(RewardsArray);
  };

  return loading ? (
    <Loader />
  ) : apiError ? (
    <Error />
  ) : (
    <div className="RewardList">
      <div className="RewardList__List">
        {RewardsToShow &&
          RewardsToShow.map((data) => {
            if (data) {
              return (
                <div
                  className="RewardList__List__Item"
                  style={{ backgroundColor: data.bgColor }}
                >
                  {/* <div className="RewardList__List__Item__Left">
                    <img loading="lazy" src={data.icon} alt="" />
                  </div> */}
                  <div className="RewardList__List__Item__Right">
                    <div className="RewardList__List__Item__Right--Offer">
                      <img loading="lazy" src={data.icon} alt="" />
                      {data.offer}
                    </div>
                    <div className="RewardList__List__Item__Right__Coupon">
                      <div className="RewardList__List__Item__Right__Coupon--Code">
                        <div className="RewardList__List__Item__Right__Coupon--Code--Label">
                          Your voucher code:
                        </div>
                        <div className="RewardList__List__Item__Right__Coupon--Code--Key">
                          {data && data.code}
                        </div>
                      </div>
                      <div
                        className="RewardList__List__Item__Right__Coupon--Copy"
                        onClick={() => setTextToCopy(data && data.code)}
                      >
                        Copy
                      </div>
                    </div>
                    <div
                      className="RewardList__List__Item__Right--How"
                      onClick={() =>
                        modalData(data.howToRedeem, data.termsAndConditions)
                      }
                    >
                      <img loading="lazy" src={info} alt="" /> How to Redeem
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <textarea
        style={{
          position: 'fixed',
          bottom: '-100vh',
        }}
        ref={textArea}
        value={textToCopy}
      />

      {showHowModal && (
        <HowToRedeem
          toggleHowModal={toggleHowModal}
          howToRedeem={howToRedeem}
          termsAndConditions={termsAndConditions}
        />
      )}
    </div>
  );
}

export default RewardList;
