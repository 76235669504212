import {IMAGE_BASE_URL} from '../config';

export const Forward = `${IMAGE_BASE_URL}Forward.svg`;
export const InviteFriends = `${IMAGE_BASE_URL}InviteFriends.png`;
export const LeaderboardImg = `${IMAGE_BASE_URL}LeaderboardImg.png`;
export const Win = `${IMAGE_BASE_URL}Win.svg`;
export const Lose = `${IMAGE_BASE_URL}Lose.svg`;
export const Gift = `${IMAGE_BASE_URL}Gift.png`;
export const arrow = `${IMAGE_BASE_URL}arrow.svg`;
export const Blank = `${IMAGE_BASE_URL}Blank.svg`;
export const Amazon = `${IMAGE_BASE_URL}Amazon.svg`;
export const Myntra = `${IMAGE_BASE_URL}Myntra.svg`;
export const PayTM = `${IMAGE_BASE_URL}PayTM.svg`;
export const RedBus = `${IMAGE_BASE_URL}RedBus.svg`;
export const ZoomIn = `${IMAGE_BASE_URL}ZoomIn.svg`;
export const boAt = `${IMAGE_BASE_URL}boAt.svg`;
export const infoBlack = `${IMAGE_BASE_URL}infoBlack.svg`;
export const TnC = `${IMAGE_BASE_URL}TnC.svg`;
export const Bullet = `${IMAGE_BASE_URL}Bullet.svg`;
export const Tick = `${IMAGE_BASE_URL}Tick.svg`;
export const info = `${IMAGE_BASE_URL}info.svg`;
export const Back = `${IMAGE_BASE_URL}Back.svg`;
export const Close = `${IMAGE_BASE_URL}Close.svg`;
export const clockB = `${IMAGE_BASE_URL}clockB.png`;
export const clockR = `${IMAGE_BASE_URL}clockR.png`;


export const rcbLogo = `${IMAGE_BASE_URL}rcbLogo.svg`;
export const dcLogo = `${IMAGE_BASE_URL}dcLogo.svg`;
export const rrLogo = `${IMAGE_BASE_URL}rrLogo.svg`;
export const cskLogo = `${IMAGE_BASE_URL}cskLogo.svg`;
export const miLogo = `${IMAGE_BASE_URL}miLogo.svg`;
export const pbksLogo = `${IMAGE_BASE_URL}pbksLogo.svg`;
export const srhLogo = `${IMAGE_BASE_URL}srhLogo.svg`;
export const kkrLogo = `${IMAGE_BASE_URL}kkrLogo.svg`;


export default Forward
