import React from 'react';

import { Close, clockB, clockR } from '../../resources/Images';

function Modal(props) {
  return (
    <div className="Modal">
      <div className="Modal__Container">
        <div className="Modal__Container__Head">
          <div className="Modal__Container___Head--Title">{props.title}</div>
          <div
            onClick={props.closeAction}
            className="Modal__Container___Head--Close"
          >
            <img loading="lazy" src={Close} alt="X" />
          </div>
        </div>
        <div className="Modal__Container__Body">{props.children}</div>
        <div className="Modal__Container__Foot" style={props.footerStyles}>
          {props.hasTimer && props.timer ? (
            <div
              className="Modal__Container__Foot--Timer"
              style={{
                color: props.lastFewHours ? '#FF4058' : 'rgba(0, 0, 0, 0.87)',
              }}
            >
              <img
                loading="lazy"
                src={props.lastFewHours ? clockR : clockB}
                alt=""
              />{' '}
              Only {props.timer} left!
            </div>
          ) : (
            ''
          )}

          <button
            className="Modal__Container__Foot--Button"
            disabled={props.disabled}
            onClick={props.buttonAction}
            style={props.buttonStyles}
          >
            {props && props.button}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
