import React from "react";
import BottomDrawer from "./BottomDrawer";
import Coupon from "./Coupon";

import "./style.scss"

const Coupons = ({ awards }) => {

    const [showBottomDrawer, setShowBottomDrawer] = React.useState(false);
    const [drawerContent, setDrawerContent] = React.useState({});

    const handleDrawerOpen = (drawerContent) => {
        setDrawerContent(drawerContent);
        setShowBottomDrawer(true);
    }

    const handleDrawerClose = () => {
        setDrawerContent({});
        setShowBottomDrawer(false);
    }

    return (
        <div className="coupons">
            <h1>Your winnings</h1>
            {awards?.map(award => <Coupon key={award?.orderId} award={award} handleDrawerOpen={handleDrawerOpen} />)}
            {showBottomDrawer && <BottomDrawer drawerContent={drawerContent} closeDrawer={handleDrawerClose} />}
        </div>
    )
}

export default Coupons;