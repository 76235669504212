import React from 'react'
import './loader.css'

function Error() {
    return (
        <div className="Error">
            <div className="Error--Text">SOMETHING WENT WRONG!</div>
            <div className="Error--Subtext">PLEASE TRY AGAIN LATER...</div>

            {/* <div className="ErrorPopUp">An Error Occured</div> */}
        </div>
    )
}

export default Error
