const token = (() => {
  let url = new URL(window.location.href);
  let token = url?.searchParams?.get('token');
  return {
    getToken: () => {
      return token || localStorage.getItem('token');
    },
    setToken: () => {
      if (token) {
        localStorage.setItem('token', token);
      }
    },
    parseToken: (token) => {
      return JSON.parse(
        token?.replace(/^[^]+\.([^]+)\.[^]+$/, (token, payload) =>
          Buffer.from(payload, 'base64')?.toString('ascii')
        )
      )?.orgId;
    },
  };
})();

export default token;
