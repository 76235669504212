import { directDeepLink } from '../../../utils/deepLinks';

const CarouselCard = ({ coupon, deepLink }) => {
  return (
    <div className="carouselCard">
      <div
        className="carouselCard__content"
        style={{ background: coupon?.color }}
      >
        <div className="carouselCard__content__img">
          <img src={coupon?.smallImage} alt="logo" />
        </div>
        <h1>{coupon?.title}</h1>
        <h4>{coupon?.text}</h4>
      </div>
      <div className="carouselCard__footer">
        <button onClick={directDeepLink(deepLink, 'SOURCE_WEBVIEW')}>
          Go to Store
        </button>
      </div>
    </div>
  );
};

export default CarouselCard;
