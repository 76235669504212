import React from 'react';

import Modal from '../UI/Modal';

import {
  Amazon,
  PayTM,
  Myntra,
  RedBus,
  ZoomIn,
  boAt,
} from '../../resources/Images';

function AwardList(props) {
  const { hasPredicted, timeLeft, toggleModal, lastFewHours, predictionMade } =
    props;
  const AwardList = [
    {
      image: Amazon,
      text: 'Amazon voucher upto Rs 200 in your Amazon pay balance',
      bgColor: '#FE6539',
    },
    {
      image: PayTM,
      text: 'Cashback of upto ₹150 in your Paytm wallet ',
      bgColor: '#3C69AB',
    },
    {
      image:
        'https://cp-assets-public.s3.amazonaws.com/campaignManager/scncL9OytblLee0T',
      text: '20% off on all Products',
      bgColor: '#FE6539',
    },
  ];

  const openStore = (url) => {
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `SCREEN_STORE,,''`
      );
    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(`SCREEN_STORE,,''`);
    }
  };

  return (
    <div className="AwardList">
      <Modal
        hasTimer={hasPredicted}
        timer={timeLeft}
        title="Get Rewards"
        button="View & Buy Courses"
        closeAction={toggleModal}
        lastFewHours={lastFewHours}
        buttonAction={() => openStore('https://on-app.in/app/store')}
      >
        <div className="AwardList__Top">
          {hasPredicted && predictionMade ? (
            <div className="AwardList__Top--Text">
              <span style={{ color: '#52B060' }}>Congratulations! </span> <br />{' '}
              You cracked today’s quiz. <br /> Now buy a course today to <br />{' '}
              win one of these rewards:
            </div>
          ) : !hasPredicted && predictionMade ? (
            <div className="AwardList__Top--Text">
              <span style={{ color: '#E59A40' }}>Oops!</span> <br />
              Come back tomorrow to <br /> get a correct answer and win one of
              these <br />
              rewards:
            </div>
          ) : (
            <div className="AwardList__Top--Text">
              <span style={{ color: '#3C69AB' }}>Answer today’s quiz!</span>{' '}
              <br />
              Play the quiz, answer <br /> correctly and stand to win <br /> one
              of these rewards.
            </div>
          )}
        </div>
        <div className="AwardList__Body">
          {AwardList &&
            AwardList.map((data) => {
              return (
                <div
                  className="AwardList__Body--Item"
                  style={{ backgroundColor: data.bgColor }}
                >
                  <div className="AwardList__Body--Item--Image">
                    <img loading="lazy" src={data.image} alt="" />
                  </div>
                  <div className="AwardList__Body--Item--Text">{data.text}</div>
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}

export default AwardList;
