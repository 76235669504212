import React from 'react';

import Modal from '../UI/Modal';

export default function Rules(props) {
  const Rules = [
    'Answer a question correctly',
    'Buy courses worth ₹100 or more before the day ends to win amazing rewards!',
  ];

  return (
    <div className="Rules">
      <Modal
        title="Quiz & Win Rules"
        closeAction={props.toggleRulesModal}
        footerStyles={{ display: 'none' }}
      >
        <div className="Rules__Top">
          {Rules &&
            Rules.map((rule, i) => {
              return (
                <div className="Rules__Top--item">
                  <div className="Rules__Top--item--bullet">{i + 1}</div>
                  <div className="Rules__Top--item--text">{rule}</div>
                </div>
              );
            })}
        </div>
        {/* <div className="Rules__Bottom">
                    Terms and Conditions go here if any. This one is a placeholder.
                </div> */}
      </Modal>
    </div>
  );
}
