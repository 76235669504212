import React from 'react';
import { useHistory } from 'react-router-dom';

import { Back } from '../../resources/Images';

function Header(props) {
  const history = useHistory();

  const goBack = () => {
    history.push('/');
  };

  return (
    <div className="Header">
      <div className="Header__Back" onClick={() => goBack()}>
        <img loading="lazy" src={Back} alt="<" />
      </div>
      <div className="Header__Title">Quiz & Win</div>
    </div>
  );
}

export default Header;
