import React, { Component } from 'react'

import {Blank, Win, Lose} from '../../resources/Images'


import Loader from '../Loader/Loader';
import Error from '../Loader/Error';

export class PointsTable extends Component {
    constructor(props){
        super(props);
        this.state={
            pointsArray: [
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
                {
                    rank: "X",
                    studentName: "XXXXXXXX",
                    totalcount: "0",
                    predictionResults: [2, 2, 2, 2]
                },
            ],
            apiData:""
        } 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.apiData !== this.props.apiData){
            this.setState({
                pointsArray: nextProps.apiData && nextProps.apiData.data,
            },()=>{
                nextProps.updateLoading(false);
            }
            )
        }
    }


    resultLog = ( value) => {
        switch(value){
            case 0: return Lose;
            case 1: return Win;
            case 2: return Blank;
            default: return Blank;
        }
    }

    render() {
        const {pointsArray} = this.state
        return (
            this.props.loading ? <Loader/> :
            this.props.apiError ? <Error/> :
            <div className="Pointstable">
                <div className="Pointstable__Head">
                    <div className="Pointstable__Head--Rank">Rank</div>
                    <div className="Pointstable__Head--Student">Student</div>
                    <div className="Pointstable__Head--Pts">Pts</div>
                    <div className="Pointstable__Head--Results">Results</div>
                </div>
                <div className="Pointstable__Body">
                    {
                        pointsArray && pointsArray.map((data)=>{
                            return (
                                <div className="Pointstable__Body__Item" style={{backgroundColor: data.myRank ? "rgba(62, 156, 76, 0.08)":"#ffffff"}}>
                                    <div className="Pointstable__Body__Item--Rank">{data.rank}</div>
                                    <div className="Pointstable__Body__Item--Student">{data.studentName}</div>
                                    <div className="Pointstable__Body__Item--Pts">{data.totalcount}</div>
                                    <div className="Pointstable__Body__Item--Results">
                                        {
                                            data.predictionResults && data.predictionResults.map((item)=>{
                                                return <img loading="lazy" src={this.resultLog(item)} alt=""/>
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default PointsTable
