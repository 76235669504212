import React, { useState } from 'react';

import Forward from '../../resources/Images';

import Rules from '../Modals/Rules';

function Banner() {
  const [showRules, setShowRules] = useState(false);

  const toggleRulesModal = () => {
    setShowRules(!showRules);
  };

  console.log(Forward);

  return (
    <div className="Banner">
      <div className="Banner__text">
        <div className="Banner__text--head">Quiz & Win</div>
        <div className="Banner__text--body">
          Answer questions about India's road to Democracy
        </div>
      </div>
      <div className="Banner__viewrules" onClick={() => toggleRulesModal()}>
        View Rules
        <img
          loading="lazy"
          className="Banner__viewrules--img"
          src={Forward}
          alt="arrow"
        ></img>
      </div>
      {/* <div className="Banner__results">View Rules <img loading="lazy" src={Forward} alt="->"/> </div> */}

      {showRules && <Rules toggleRulesModal={toggleRulesModal} />}
    </div>
  );
}

export default Banner;
