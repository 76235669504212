import React, { useEffect, useState } from 'react';

function Quiz(props) {
  const {
    data,
    selectedOption,
    activeQuestionIndex,
    activeQuestion,
    selectOption,
    setActiveQuestion,
    submitAnswer,
    answerLoading,
  } = props;

  const [buttonDisabled, updateDisabled] = useState(true);

  useEffect(() => {
    if (selectedOption) {
      updateDisabled(false);
    } else {
      updateDisabled(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    console.log(
      activeQuestion,
      'XXXXXXXXXXXXX',
      selectedOption,
      '!!!!!!!!!!!!!!!',
      buttonDisabled
    );
  }, [activeQuestion, selectedOption]);

  useEffect(() => {
    if (activeQuestion) {
      console.log(activeQuestion);
    }
  }, [activeQuestion]);

  const testArray = [1, 1, 1, 1];
  return (
    <div className="Quiz">
      {/* <div className="Quiz__Heading">Today's Quiz</div> */}
      <div className="Quiz__Body">
        <div className="Quiz__Body__Content">
          <div className="Quiz__Body__Content--Question">
            {activeQuestion && activeQuestion.question}
          </div>
          <div className="Quiz__Body__Content--Options">
            {activeQuestion &&
              activeQuestion.options &&
              activeQuestion.options.map((data, i) => {
                return (
                  <div
                    className={
                      (selectedOption && selectedOption.key) ===
                      (data && data.key)
                        ? 'Quiz__Body__Content--Options--Point Selected'
                        : 'Quiz__Body__Content--Options--Point'
                    }
                    onClick={() => {
                      selectOption(data);
                    }}
                  >
                    <input
                      type="radio"
                      checked={
                        (selectedOption && selectedOption.key) ===
                        (data && data.key)
                      }
                    />
                    <span className="Quiz__Body__Content--Options--Point--Text">
                      {data && data.value}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <button
          disabled={buttonDisabled || answerLoading}
          className="Quiz__Body__Bottom"
          onClick={submitAnswer}
        >
          Submit Answer
        </button>
      </div>
    </div>
  );
}

export default Quiz;
