import React, { Component } from 'react';

import Rewards from './Rewards';
import Invite from './Invite';
import ViewRewards from './ViewRewards';
import Loader from '../Loader/Loader';
import Error from '../Loader/Error';
import Quiz from './Quiz';

export class Body extends Component {
  render() {
    const {
      data,
      results,
      loading,
      apiError,
      selectedOption,
      activeQuestionIndex,
      activeQuestion,
      selectOption,
      setActiveQuestion,
      submitAnswer,
      predictionCorrect,
      awardCountDown,
      showRewards,
      toggleRewardModal,
      predictionMade,
      answerLoading,
    } = this.props;

    return loading ? (
      <Loader />
    ) : apiError ? (
      <Error />
    ) : (
      <div className="HomeBody">
        <div className="Quiz__Heading">
          {data && data.questions && data.questions.length > 0
            ? " Today's Quiz"
            : predictionCorrect
            ? 'You won in today’s quiz!'
            : 'Come back tomorrow for more!'}
        </div>
        {data && data.questions && data.questions.length > 0 && (
          <Quiz
            data={data && data.questions}
            selectedOption={selectedOption}
            activeQuestion={activeQuestion}
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestion={setActiveQuestion}
            submitAnswer={submitAnswer}
            selectOption={selectOption}
            answerLoading={answerLoading}
          />
        )}

        <Rewards
          predictionMade={data?.questions?.length === 0}
          hasPredicted={predictionCorrect}
          studentPurchaseTimeLeft={awardCountDown}
          showRewards={showRewards}
          toggleRewardModal={toggleRewardModal}
          data={data?.rewardsCard}
        />

        {data && data.showRewardsCard ? <ViewRewards /> : ''}
        {data?.inviteFriendsCard ? (
          <Invite data={data?.inviteFriendsCard} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Body;
