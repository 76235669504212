import React from 'react';
import { directDeepLink } from '../../../utils/deepLinks';
import { info } from '../../../resources/Images';

const Coupon = ({ award, handleDrawerOpen }) => {
  const { color, logo, winningText, code } = award;

  const buttonRef = React.useRef();

  const copyCodeText = 'Copy Code';
  const copiedText = 'Copied';
  const copiedClassName = 'copied';

  const handleCodeCopied = () => {
    buttonRef.current.classList.add(copiedClassName);
    buttonRef.current.innerText = copiedText;
    setTimeout(() => {
      buttonRef.current.classList.remove(copiedClassName);
      buttonRef.current.innerText = copyCodeText;
    }, 1000);
  };

  const handleCopyCode = (code) => {
    handleCodeCopied();
    // window.navigator.clipboard.writeText(code);
    directDeepLink(
      { screen: 'UTIL_COPY', paramOne: code, paramTwo: '' },
      'SOURCE_WEBVIEW'
    )();
  };

  return (
    <div className="coupon" style={{ background: color }}>
      <div className="coupon__header">
        <div className="coupon__header__img">
          <img src={logo} alt="logo" />
        </div>
        <p>{winningText}</p>
      </div>
      <div className="coupon__main">
        <div className="coupon__main__code">
          <p>Your voucher code</p>
          <h3>{code}</h3>
        </div>
        <button ref={buttonRef} onClick={() => handleCopyCode(code)}>
          Copy code
        </button>
      </div>
      <div
        className="coupon__footer"
        onClick={() => handleDrawerOpen(award?.coupon)}
      >
        <img src={info} alt="infoIcon" />
        <p>How to Redeem</p>
      </div>
    </div>
  );
};

export default Coupon;
