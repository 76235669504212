import React from 'react';

import { InviteFriends } from '../../resources/Images';
import { directDeepLink } from '../../utils/deepLinks';

function Invite({ data }) {
  const openStore = (url) => {
    const whatAppText = encodeURIComponent(
      `Hey! Play this Olympics Quiz and win exciting rewards ranging from Amazon, Paytm and GoDesi. Hurry Up! Learn and Play on this app. `
    );
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_BROWSER,https://wa.me/?text=${whatAppText}${url},''`
      );
    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_BROWSER,https://wa.me/?text=${whatAppText}${url},''`
      );
    }
  };

  return (
    <div className="Invite">
      <div className="Invite__content">
        <div className="Invite__content--text">{data?.title}</div>
        <div
          className="Invite__content--button"
          onClick={directDeepLink(data?.button?.deeplink, 'SOURCE_WEBVIEW')}
        >
          {data?.button?.text}
        </div>
      </div>

      <img
        loading="lazy"
        className="Invite__image"
        src={InviteFriends}
        alt="gift"
      ></img>
    </div>
  );
}

export default Invite;
