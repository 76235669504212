import { postAxios } from './axios';
import { API_DEEPLINK } from './constants';

export const directDeepLink = (url, source, showAlert) => {
  switch (source) {
    case 'SOURCE_HOME':
      return () => {
        window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
          JSON.stringify(url)
        );
        window?.mobile?.exceuteDeeplink(JSON.stringify(url));
        postAxios(API_DEEPLINK, { deeplink: url });
      };
    case 'SOURCE_WEBVIEW':
      return () => {
        console.log(
          'DEEPLINK',
          `${url?.screen},${url?.paramOne},${url?.paramTwo}`
        );
        if (url?.paramThree?.length > 0) {
          window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage(
            `${url?.screen},${url?.paramOne},${url?.paramTwo}`
          );
        } else {
          window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage(
            `${url?.screen},${url?.paramOne},${url?.paramTwo}`
          );
        }
        window?.mobile?.onDeeplinkExecutedV2(JSON.stringify(url));
        postAxios(API_DEEPLINK, { deeplink: url });
      };
    default:
      return;
  }
};
