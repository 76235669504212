import React, { Component } from 'react';
import { BASE_URL } from '../../config';
import Banner from './Banner';
import Body from './Body';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      apiData: '',
      results: '',
      loading: true,
      apiError: false,
      activeQuestionIndex: 0,
      activeQuestion: '',
      selectedOption: '',
      awardCountDown: '',
      predictionMade: false,
      predictionCorrect: false,
      showRewards: false,
      answerLoading: false,
    };
  }

  componentDidMount() {
    this.getApiData();
  }

  selectOption = (param) => {
    this.setState({
      selectedOption: param,
    });
  };

  toggleRewardModal = () => {
    this.setState({
      showRewards: !this.state.showRewards,
    });
  };

  setActiveQuestion = () => {
    const { apiData, activeQuestionIndex } = this.state;
    this.setState({
      activeQuestion:
        apiData && apiData.questions && apiData.questions[activeQuestionIndex],
    });
  };

  submitAnswer = () => {
    const { selectedOption } = this.state;
    if (selectedOption) {
      this.verifySelectedOption();
    }
  };

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split('&')
      .map((qStr) => qStr.split('='))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  verifySelectedOption = () => {
    const { selectedOption, activeQuestion } = this.state;

    this.setState({
      answerLoading: true,
    });

    let queryToken =
      this.getQuery()['token'] || localStorage.getItem('pnwToken');
    localStorage.setItem('pnwToken', queryToken);

    fetch(
      `${BASE_URL}v2/course/campaign/quiz/answer?questionId=${
        activeQuestion && activeQuestion.id
      }&answerKey=${selectedOption && selectedOption.key}&isCorrect=${
        selectedOption && selectedOption.correct
      }`,
      {
        method: 'GET',
        headers: {
          'x-access-token': queryToken,
          Accept: 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            awardCountDown: res && res.data && res.data.purchaseCounter,
            predictionMade: true,
            predictionCorrect: selectedOption && selectedOption.correct,
          },
          () => {
            if (selectedOption && selectedOption.correct) {
              toast.success(
                res && res.data && res.data.toast && res.data.toast.title
              );

              setTimeout(() => {
                this.toggleRewardModal();
                this.getApiData();
                // this.setState({
                //   answerLoading: false,
                // });
              }, 2000);
            } else {
              toast.error(
                res && res.data && res.data.toast && res.data.toast.title
              );
              setTimeout(() => {
                this.getApiData();
                // this.setState({
                //   answerLoading: false,
                // });
              }, 2000);
            }
          }
        );
      });
  };

  getApiData = () => {
    let queryToken =
      this.getQuery()['token'] || localStorage.getItem('pnwToken');
    localStorage.setItem('pnwToken', queryToken);
    fetch(`${BASE_URL}v2/course/campaign/quiz/home`, {
      method: 'GET',
      headers: {
        'x-access-token': queryToken,
        Accept: 'application/json',
        // 'Api-Version': 8,
      },
    })
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState(
            {
              apiData: res && res.data,
              awardCountDown: res && res.data && res.data.purchaseCounter,
              predictionCorrect:
                res && res.data && res.data.showPurchaseCounter,
              loading: false,
              showRewards: res?.data?.showBottomSheet,
              answerLoading: false,
            },
            () => {
              this.selectOption();
              this.setActiveQuestion();
            }
          );
        },
        (err) => {
          console.log(err);
          this.setState({
            // apiError: true,
            loading: false,
            answerLoading: false,
          });
        }
      );
  };

  render() {
    const {
      apiData,
      results,
      loading,
      apiError,
      selectedOption,
      activeQuestion,
      activeQuestionIndex,
      awardCountDown,
      predictionCorrect,
      showRewards,
      predictionMade,
      answerLoading,
    } = this.state;

    return (
      <>
        <Banner />
        <Body
          data={apiData}
          results={results}
          loading={loading}
          apiError={apiError}
          selectedOption={selectedOption}
          activeQuestionIndex={activeQuestionIndex}
          activeQuestion={activeQuestion}
          selectOption={this.selectOption}
          setActiveQuestion={this.setActiveQuestion}
          submitAnswer={this.submitAnswer}
          awardCountDown={awardCountDown}
          predictionCorrect={predictionCorrect}
          showRewards={showRewards}
          toggleRewardModal={this.toggleRewardModal}
          predictionMade={predictionMade}
          answerLoading={answerLoading}
        />

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default Home;
