import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.scss"

import Slider from "react-slick";
import CarouselCard from "./CarouselCard";

const Carousel = ({ subHeading, coupons, deepLink }) => {

    const carouselConfig = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        swipeToSlide: true,
    };

    return (
        <div className="carouselComponent">
            <div className="carouselComponent__header">
                <h1>Rewards up for grabs</h1>
                <p>{subHeading}</p>
            </div>
            <div className="carouselComponent__slick">
                <Slider {...carouselConfig}>
                    {coupons?.map(coupon => <div className="carouselComponent__slick__cardContainer" key={coupon?.title}>
                        <CarouselCard coupon={coupon} deepLink={deepLink} />
                    </div>)}
                </Slider>
            </div>
        </div>
    )
}

export default Carousel;