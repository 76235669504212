import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config'

import Header from '../UI/Header'
import PointsTable from './PointsTable'

function Leaderboards() {

    const [apiData, updateApiData] = useState();
    const [loading, updateLoading] = useState(true)
    const [apiError, updateApiError] = useState(false)

    useEffect(() => {
        getApiData()
    },[])

    const getApiData = () =>{
        let queryToken = getQuery()["token"] || localStorage.getItem("pnwToken")
        // localStorage.setItem("pnwToken", queryToken)
        fetch(`${BASE_URL}v2/course/predictAndWin/leaderBoard`,{
            method: "GET",
            headers: {
                "x-access-token": queryToken
            }
        })
        .then(res => res.json())
        .then(
            (res) => {
                updateApiData(res)
                // updateLoading(false)
            },
            (err) => {
                console.log(err);
                updateApiError(true);
                updateLoading(false);
            }
        )
    }
    const getQuery = () => {
        let b = window.location.search
          .slice(1)
          .split("&")
          .map((qStr) => qStr.split("="))
          .reduce((acc, inc) => {
            acc[inc[0]] = inc[1];
            return acc;
          }, {});
        return b;
    };
    return (
        <div className="Leaderboards">
            <Header />
            <div className="Leaderboards__Banner">
                <div className="Leaderboards__Banner--Head">Leaderboard</div>
                <div className="Leaderboards__Banner--Text">
                    Check your ranking among other <br/> students based on correct predictions
                </div>
            </div>

            <PointsTable 
             apiData={apiData} 
             updateLoading={updateLoading} 
             updateApiError={updateApiData} 
             loading={loading} 
             apiError={apiError}
            />
            
            
        </div>
    )
}

export default Leaderboards
