import React from 'react'
import './loader.css'

function Loader() {
    return (
        <div className="LoaderContainer">
            <div class="loader"></div>
        </div>
    )
}

export default Loader
